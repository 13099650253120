import { CENTS_IN_DOLLAR, Currency } from '~/shared/constants'
import {
  IRawSubscription,
  ISubscription,
  TOldPrices,
  TRawPricesAmount,
} from '~/shared/types'

const getFormattedPrice = (price: number | undefined): number =>
  price ? price / CENTS_IN_DOLLAR : 0

const getOldPricesList = (oldPrice: TRawPricesAmount): TOldPrices => ({
  fullPrice: getFormattedPrice(oldPrice?.amount),
  amountOfDiscount: getFormattedPrice(oldPrice?.amount_of_discount),
  weekly: getFormattedPrice(oldPrice?.in_the_box.weekly),
  daily: getFormattedPrice(oldPrice?.in_the_box.daily),
  monthly: getFormattedPrice(oldPrice?.in_the_box.monthly),
  yearly: getFormattedPrice(oldPrice?.in_the_box.yearly),
  percentOfDiscount: oldPrice?.percent_of_discount,
})

export const getSubscriptionListFromRawSubscriptionList = (
  rawSubscriptionList: IRawSubscription,
): ISubscription[] =>
  rawSubscriptionList?.plans.map((rawSubscription) => ({
    id: rawSubscription.paywall.show_order.toString(),
    planId: Number(rawSubscription.id),
    inAppId: rawSubscription.id,
    product: rawSubscription.product_key,
    paypalPlanId: rawSubscription.paypal?.plan_id,
    isDefault: rawSubscription.paywall.is_default,
    currency: rawSubscription.pricing.currency.toLowerCase() as Currency,
    lookupKey: rawSubscription.stripe.main_lookup_key,
    trialLookupKey: rawSubscription.stripe.intro_lookup_key,
    paywallDiscountPercent: rawSubscription.paywall.discount_percent,

    introDiff: {
      discountAmount: getFormattedPrice(
        rawSubscription.intro_diff?.discount_amount,
      ),
      discountPercentage: rawSubscription.intro_diff?.discount_percent,
    },

    mainPrices: {
      periodName: rawSubscription.pricing.recurring?.interval,
      periodQuantity: rawSubscription.pricing.recurring?.interval_count,
      fullPrice: getFormattedPrice(rawSubscription.main.current_price.amount),
      fullPriceTax: getFormattedPrice(
        rawSubscription.main.current_price.tax_amount,
      ),
      discountAmount:
        rawSubscription.main.current_price.amount_of_discount / CENTS_IN_DOLLAR,
      daily:
        rawSubscription.main.current_price.in_the_box.daily / CENTS_IN_DOLLAR,
      weekly:
        rawSubscription.main.current_price.in_the_box.weekly / CENTS_IN_DOLLAR,
      monthly:
        rawSubscription.main.current_price.in_the_box.monthly / CENTS_IN_DOLLAR,
      yearly:
        rawSubscription.main.current_price.in_the_box.yearly / CENTS_IN_DOLLAR,

      oldPrices: {
        beforeCustomDiscount:
          rawSubscription.main?.old_prices.before_custom_discount &&
          getOldPricesList(
            rawSubscription.main?.old_prices.before_custom_discount,
          ),
        before33PercentDiscount: getOldPricesList(
          rawSubscription.main?.old_prices.before_33_percent_discount,
        ),
        before50PercentDiscount: getOldPricesList(
          rawSubscription.main?.old_prices.before_50_percent_discount,
        ),
        before75PercentDiscount: getOldPricesList(
          rawSubscription.main?.old_prices.before_75_percent_discount,
        ),
      },
    },

    trialPrices: {
      fullPrice: getFormattedPrice(rawSubscription.trial?.current_price.amount),
      fullPriceTax: getFormattedPrice(
        rawSubscription.trial?.current_price.tax_amount,
      ),
      durationDays: rawSubscription.pricing.trial?.duration_days,
      daily: getFormattedPrice(
        rawSubscription.trial?.current_price.in_the_box.daily,
      ),
      weekly: getFormattedPrice(
        rawSubscription.trial?.current_price.in_the_box.weekly,
      ),
      monthly: getFormattedPrice(
        rawSubscription.trial?.current_price.in_the_box.monthly,
      ),
      yearly: getFormattedPrice(
        rawSubscription.trial?.current_price.in_the_box.yearly,
      ),

      oldPrices: {
        beforeCustomDiscount:
          rawSubscription.trial?.old_prices.before_custom_discount &&
          getOldPricesList(
            rawSubscription.trial?.old_prices.before_custom_discount,
          ),
        before33PercentDiscount: getOldPricesList(
          rawSubscription.trial?.old_prices.before_33_percent_discount,
        ),
        before50PercentDiscount: getOldPricesList(
          rawSubscription.trial?.old_prices.before_50_percent_discount,
        ),
        before75PercentDiscount: getOldPricesList(
          rawSubscription.trial?.old_prices.before_75_percent_discount,
        ),
      },
    },
  }))
