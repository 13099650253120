import { StripeError } from '@stripe/stripe-js'

import {
  Events,
  OutbrainEventName,
  PaymentMethod,
  PaymentSystem,
  ScreenName,
  TaboolaEventName,
  TimeInterval,
} from '~/shared/constants'
import { eventLogger, googleAnalyticsLogger } from '~/shared/services'
import { TProductId } from '~/shared/types'

export const logSuccessfulPayment = ({
  productId,
  price,
  trialPrice,
  trialPeriodDays,
  trialPriceId,
  subscriptionId,
  discountApplied,
  uuid,
  periodName,
  periodQuantity,
  paymentMethod,
  paymentSystem,
  currency,
  screenName,
  email,
  eventName = Events.PURCHASE_COMPLETED,
  stripeAccountId,
  stripeAccountName,
  isUpsell,
  isInApp,
  isPersonalDataAllowed,
  predictedLtv,
}: {
  productId: TProductId
  price: number
  trialPrice: number
  trialPeriodDays: number
  trialPriceId: string
  subscriptionId: string
  discountApplied?: string
  uuid: string
  periodName: TimeInterval | null
  periodQuantity: number | null
  paymentMethod?: PaymentMethod
  paymentSystem: PaymentSystem
  currency: string
  screenName: ScreenName
  email: string
  eventName?: Events
  stripeAccountId: string
  stripeAccountName: string
  isPersonalDataAllowed: boolean
  isUpsell?: boolean
  isInApp?: boolean
  predictedLtv?: number
}): void => {
  if (trialPeriodDays) {
    window.fbq(
      'track',
      'StartTrial',
      {
        value: trialPrice,
        currency,
        subscription_id: subscriptionId,
        // This field and similar ones below have been added to avoid FB pixel issue. https://github.com/facebook/facebook-nodejs-business-sdk/issues/164
        subscription_sid: subscriptionId,
      },
      { eventID: uuid },
    )
  }
  window.ttq.identify({ email: isPersonalDataAllowed ? email : '' })

  if (!isUpsell && !isInApp) {
    window.fbq(
      'track',
      'Purchase',
      {
        value: predictedLtv || trialPrice || price,
        currency,
        subscription_id: subscriptionId,
        subscription_sid: subscriptionId,
      },
      { eventID: uuid },
    )
    window.fbq(
      'track',
      'Subscribe',
      {
        value: trialPrice || price,
        currency,
        subscription_id: subscriptionId,
        subscription_sid: subscriptionId,
      },
      { eventID: uuid },
    )
    window.ttq.track('CompletePayment', {
      value: trialPrice || price,
      currency,
      content_id: subscriptionId,
    })
    window.snaptr('track', 'PURCHASE', {
      price: trialPrice || price,
      currency,
      transaction_id: subscriptionId,
      user_email: isPersonalDataAllowed ? email : '',
    })
    window.obApi &&
      window.obApi('track', OutbrainEventName.PURCHASE, {
        orderValue: trialPriceId ? trialPrice : price,
        orderId: subscriptionId,
        currency: currency.toUpperCase(),
      })
    window._tfa &&
      window._tfa.push({
        notify: 'event',
        name: TaboolaEventName.PURCHASE,
        revenue: trialPrice || price,
        orderId: subscriptionId,
        currency: currency.toUpperCase(),
      })
  }

  eventLogger.logPurchaseCompleted({
    event: eventName,
    productId,
    priceDetails: {
      price,
      trial: isUpsell ? false : !!trialPeriodDays,
      currency,
    },
    paymentMethod,
    paymentSystem,
    screenName,
    discountApplied,
    stripeAccountId,
    stripeAccountName,
  })

  googleAnalyticsLogger.logPurchaseCompleted({
    subscriptionId,
    price,
    periodName,
    periodQuantity,
    currency,
    screenName,
    isUpsell,
    isInApp,
  })
}

export const logFailedPayment = ({
  productId,
  priceDetails,
  paymentResponse: { type, code, message, decline_code: declineCode },
  paymentMethod,
  screenName,
  stripeAccountId,
  stripeAccountName,
}: {
  productId: TProductId
  priceDetails: { price: number; trial: boolean; currency: string }
  paymentResponse: StripeError
  paymentMethod: PaymentMethod
  screenName: ScreenName
  stripeAccountId: string
  stripeAccountName: string
}): void => {
  const errorCode = code === 'card_declined' ? `${code}:${declineCode}` : code

  eventLogger.logPurchaseFailed({
    productId,
    priceDetails,
    paymentMethod,
    screenName,
    stripeAccountId,
    stripeAccountName,
    paymentSystem: PaymentSystem.STRIPE,
    error: { type, code: errorCode, description: message },
  })
}
