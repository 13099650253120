import React, { FC, ReactNode, SyntheticEvent, useMemo } from 'react'

import { t } from 'i18next'

import { OuterLink } from '~/shared/components/OuterLink'
import { getCurrentEnvironment } from '~/shared/utils'

type TSupportActionLinkProps = {
  children?: ReactNode
  className?: string
  onClick?: (event: SyntheticEvent<HTMLAnchorElement>) => void
}

export const SupportActionLink: FC<TSupportActionLinkProps> = ({
  children = t('actions.contactSupport'),
  className,
  onClick,
}) => {
  const currentEnvironment = getCurrentEnvironment()

  const link = useMemo(() => {
    const { isProdEnvironment, isStageEnvironment } = currentEnvironment

    if (isProdEnvironment) return 'https://account.nutrimate.fit/contact-form'
    if (isStageEnvironment) {
      return 'https://account-nutrimate.gistage.com/contact-form'
    }

    return 'https://account-nutrimate.gidev.xyz/contact-form'
  }, [currentEnvironment])

  return (
    <OuterLink className={className} href={link} onClick={(e) => onClick?.(e)}>
      {children}
    </OuterLink>
  )
}
