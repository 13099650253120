export const enum Cohort {
  MF_INTRO = 'mf_intro',
  MF_INTRO_2 = 'mf_intro2',
  MF_INTRO_SALE = 'mf_intro_sale',
  MF_FLOW_2 = 'mf_flow2',
  MF_FLOW_2_INTRO_2 = 'mf_flow2intro2',
  MF_MEN_FLOW = 'mf_menflow',
  MF_WOMEN_AGE = 'mf_womenage',
  MF_WOMEN_45 = 'mf_women_45',
  MF_KETO = 'mf_keto',
  MF_NOSUGAR = 'mf_nosugar',
  MF_MEALPLAN = 'mf_mealplan',
  MF_SARAH_JAKE = 'mf_sarah_jake',
  MF_INFLUENCER = 'mf_influencer',
  MF_REFUND = 'mf_refund',
  MF_SCANNER = 'mf_scanner',
  MF_WATERFASTING = 'mf_waterfasting',
  MF_BELLY = 'mf_belly',
  MF_SCC = 'mf_scc',
  MF_INTRO_2_TT = 'mf_intro2_tt',
}

export const MEN_COHORTS = [Cohort.MF_MEN_FLOW]
export const WOMEN_COHORTS = [Cohort.MF_WOMEN_AGE, Cohort.MF_WOMEN_45]
export const COHORTS_WITHOUT_TRIAL_FOR_WEEKLY_PLAN = [
  Cohort.MF_INTRO_2,
  Cohort.MF_WOMEN_AGE,
  Cohort.MF_WOMEN_45,
  Cohort.MF_FLOW_2_INTRO_2,
  Cohort.MF_MEN_FLOW,
]
export const COHORTS_BASED_ON_FLOW2 = [
  Cohort.MF_FLOW_2,
  Cohort.MF_FLOW_2_INTRO_2,
  Cohort.MF_MEN_FLOW,
]
export const COHORTS_WITH_HEADER_V2 = [
  Cohort.MF_KETO,
  Cohort.MF_MEALPLAN,
  Cohort.MF_NOSUGAR,
  Cohort.MF_SARAH_JAKE,
  Cohort.MF_INTRO,
  Cohort.MF_SCC,
]
