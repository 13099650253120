import { useSelector } from 'react-redux'

import { PageId, PurchaseProductDuration } from '~/shared/constants'
import { useExperimentalFeatures } from '~/shared/hooks/useExperimentalFeatures'
import { usePageInfo } from '~/shared/providers/PageInfoProvider'
import { selectUserStatus } from '~/shared/store/user'

export const useUserStatus = () => {
  const userStatus = useSelector(selectUserStatus)
  const { isRecurringFirstUpsell } = useExperimentalFeatures()

  const {
    steps,
    currentSubscriptionPageId,
    currentUpsellPaywallPageId,
    activeUpsellPageIds,
  } = usePageInfo()

  const isSevenDaysSubscription =
    userStatus?.config.subscriptionDuration ===
    PurchaseProductDuration.SEVEN_DAY
  const shouldSkipUpsell = isRecurringFirstUpsell && isSevenDaysSubscription

  if (userStatus?.account.hasAccount && userStatus?.hasSubscription) {
    return PageId.DOWNLOAD
  }

  if (
    (userStatus?.hasSubscription && !activeUpsellPageIds?.length) ||
    shouldSkipUpsell
  ) {
    return PageId.FINISHING_TOUCHES
  }

  if (userStatus?.hasSubscription && activeUpsellPageIds?.length) {
    return currentUpsellPaywallPageId
  }

  if (userStatus?.email.hasEmail && !userStatus?.hasSubscription) {
    return currentSubscriptionPageId || steps[0].id
  }

  return '' as PageId
}
