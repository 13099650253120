import { useEffect, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { CookieConsentValue } from '~/shared/constants'
import { selectCookieConsentCountries } from '~/shared/store/common'
import {
  selectCookieConsent,
  selectUserCountryCode,
  setIsPersonalDataAllowedAction,
} from '~/shared/store/user'

export const useCookieConsentAnswer = () => {
  const dispatch = useDispatch()
  const countryCode = useSelector(selectUserCountryCode)
  const cookieConsent = useSelector(selectCookieConsent)
  const cookieConsentCountries = useSelector(selectCookieConsentCountries)

  const isEUUser = useMemo(
    () => cookieConsentCountries.includes(countryCode.toUpperCase()),
    [cookieConsentCountries, countryCode],
  )

  const hasAdvertisingAndTargetingCookie = useMemo(
    () => cookieConsent?.includes(CookieConsentValue.ADVERTISING_AND_TARGETING),
    [cookieConsent],
  )

  const hasFunctionalCookie = useMemo(
    () => cookieConsent?.includes(CookieConsentValue.FUNCTIONAL_COOKIES),
    [cookieConsent],
  )

  const hasAnalyticsAndPerformanceCookie = useMemo(
    () => cookieConsent?.includes(CookieConsentValue.ANALYTICS_AND_PERFORMANCE),
    [cookieConsent],
  )

  const isPersonalDataAllowed = useMemo(
    () => (isEUUser && hasFunctionalCookie) || !isEUUser,
    [isEUUser, hasFunctionalCookie],
  )

  useEffect(() => {
    dispatch(setIsPersonalDataAllowedAction(isPersonalDataAllowed))
  }, [dispatch, isPersonalDataAllowed])

  return {
    isEUUser,
    hasAdvertisingAndTargetingCookie,
    hasFunctionalCookie,
    hasAnalyticsAndPerformanceCookie,
    cookieConsent,
    isPersonalDataAllowed,
  }
}
