import {
  API_APP_NAME,
  RequestMethod,
  TEST_ENV_QUERY_PARAM,
} from '~/shared/constants'
import { ApiService } from '~/shared/services'
import {
  IBindUserResponseRaw,
  IGetUserStatusResponseRaw,
  IRequestOptions,
  IResponseResult,
  TAnswers,
  TUserConfig,
  TUtmTags,
} from '~/shared/types'

export class UserApi {
  private readonly baseUrlV2 = 'v2/leads'
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  bindUser(payload: {
    token: string
    uuid: string
  }): Promise<IResponseResult<IBindUserResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        token: payload.token,
      },
    }

    return this.api.makeRequest<IBindUserResponseRaw>(
      `${this.baseUrlV2}/${payload.uuid}/${API_APP_NAME}/signup`,
      options,
    )
  }

  getUUID({
    cohort,
    appId,
    locale,
    promoCode = '',
    utm,
  }: {
    cohort: string
    appId: string
    locale: string
    promoCode?: string
    utm: TUtmTags
  }): Promise<IResponseResult<string>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        cohort,
        locale,
        app_id: appId,
        app_name: API_APP_NAME,
        utm,
        promo_code: promoCode,
        is_test_environment:
          document.location.search.includes(TEST_ENV_QUERY_PARAM),
      },
    }

    return this.api.makeRequest<string>(this.baseUrlV2, options)
  }

  getUserUUIDAndSecret(
    token: string,
  ): Promise<IResponseResult<{ secret: string; uuid: string }>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
      params: { token },
    }

    return this.api.makeRequest<{ secret: string; uuid: string }>(
      `v1/manage/login`,
      options,
    )
  }

  getUserStatus(
    uuid: string,
  ): Promise<IResponseResult<IGetUserStatusResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    return this.api.makeRequest<IGetUserStatusResponseRaw>(
      `${this.baseUrlV2}/${uuid}/${API_APP_NAME}`,
      options,
    )
  }

  getAutologinToken(
    uuid: string,
  ): Promise<IResponseResult<{ status: boolean; custom_token: string }>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        uuid,
        app_name: API_APP_NAME,
      },
    }

    return this.api.makeRequest<{ status: boolean; custom_token: string }>(
      `v1/flow/login`,
      options,
    )
  }

  saveFacebookParams({
    fbc,
    fbp,
    uuid,
  }: {
    uuid: string
    fbc: string
    fbp: string
  }): Promise<IResponseResult<string>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        fbc,
        fbp,
      },
    }

    return this.api.makeRequest<IResponseResult<string>>(
      `${this.baseUrlV2}/${uuid}/${API_APP_NAME}/facebook`,
      options,
    )
  }

  saveGoogleAdsParams({
    uuid,
    gclid,
    gaid,
  }: {
    uuid: string
    gclid: string
    gaid: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      params: { ctid: gclid, gaid },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/google`,
      options,
    )
  }

  saveUserAnswers({
    uuid,
    answers,
    isFinished = false,
    isSkipped = false,
  }: {
    uuid: string
    answers: TAnswers
    isFinished?: boolean
    isSkipped?: boolean
  }): Promise<IResponseResult<IGetUserStatusResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        is_finished: isFinished,
        is_skipped: isSkipped,
        quiz: answers,
        version: '2.0',
      },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${API_APP_NAME}/quiz`,
      options,
    )
  }

  saveUserEmail({
    uuid,
    email,
    consentRequired,
  }: {
    uuid: string
    email: string
    consentRequired?: boolean
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        email,
        consent_required: consentRequired,
      },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${API_APP_NAME}/email`,
      options,
    )
  }

  saveUserEmailConsent({
    uuid,
    consented,
  }: {
    uuid: string
    consented: boolean
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        consented,
      },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${API_APP_NAME}/email/consent`,
      options,
    )
  }

  saveUserConfig({
    uuid,
    config,
  }: {
    uuid: string
    config: TUserConfig
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        config,
      },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${API_APP_NAME}/config`,
      options,
    )
  }

  savePlanAdditions({
    uuid,
    planAdditions,
  }: {
    uuid: string
    planAdditions: string[]
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: { addons: planAdditions },
    }

    return this.api.makeRequest<never>(
      `${this.baseUrlV2}/${uuid}/${API_APP_NAME}/addons`,
      options,
    )
  }

  getUserPredictedLtv(
    uuid: string,
  ): Promise<IResponseResult<{ status: boolean; predicted_ltv: number }>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    return this.api.makeRequest<{ status: boolean; predicted_ltv: number }>(
      `${this.baseUrlV2}/${uuid}/${API_APP_NAME}/predicted_ltv`,
      options,
    )
  }

  saveUserSCCID({
    sccid,
    scid,
    uuid,
  }: {
    sccid: string
    scid: string
    uuid: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      params: { sccid, uuid_c1: scid },
    }

    return this.api.makeRequest(`${this.baseUrlV2}/${uuid}/snapchat`, options)
  }
}
