import styled from 'styled-components'

import checkIcon from '~/shared/assets/images/check.svg'
import { Color, Position } from '~/shared/constants'

type TProps = {
  checkboxPosition?: Position
  iconPosition?: Position
  strikethroughIcon?: boolean
  withCheckbox?: boolean
}

const getPadding = (checkboxPosition?: Position, withCheckbox?: boolean) => {
  if (withCheckbox) {
    switch (checkboxPosition) {
      case Position.LEFT:
        return '0 4px 0 52px'
      case Position.RIGHT:
        return '0 44px 0 16px'
      default:
        return '0 8px 0 16px'
    }
  }

  return '0 8px 0 16px'
}

export const StyledAnswerButtonV2 = {
  Wrapper: styled.div<TProps>`
    ${({ withCheckbox, iconPosition, checkboxPosition }) => `
      align-items: center;
      background: ${Color.LIGHT};
      border: 2px solid ${Color.LIGHT};
      border-radius: 12px;
      color: ${Color.GREY_100};
      cursor: pointer;
      display: grid;
      grid-template-columns: ${
        iconPosition === Position.LEFT ? 'auto 1fr' : '1fr auto'
      };
      grid-template-rows: 100%;
      resize: both;
      gap: ${iconPosition === Position.LEFT ? '8px 22px' : '8px'};
      height: 80px;
      justify-content: ${
        iconPosition === Position.LEFT ? 'flex-start' : 'space-between'
      };
      max-width: 100%;
      min-height: 80px;
      padding: ${getPadding(checkboxPosition, withCheckbox)};
     
      position: relative;
      transition: 0.2s ease-out;
      width: 100%;

      &::after {
        background: transparent;
        border: 2px solid ${Color.GREY};
        border-radius: 4px;
        content: '';
        display: ${withCheckbox ? 'block' : 'none'};
        height: 18px;
        width: 18px;
        mix-blend-mode: normal;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        ${checkboxPosition === Position.LEFT ? 'left: 18px;' : 'right: 18px;'}
      }

      input:disabled + & {
        opacity: 0.5;
      }

      input:checked + & {
        background: ${Color.SECONDARY};
        border: 2px solid ${Color.PRIMARY};
        color: ${Color.WHITE};
      }

      input:checked + &::after {
        background: ${Color.WHITE} url(${checkIcon}) no-repeat center;
        background-size: 11px 8.5px;
        border-color: ${Color.PRIMARY};
        opacity: 1;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: ${Color.GREY_100};
        flex-basis: 100%;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.45px;
        line-height: 22px;
        margin-bottom: 2px;
      }

      p {
        color: ${Color.DISABLED};
        flex-basis: 100%;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.154px;
        line-height: 18px;
      }
    `}
  `,

  Content: styled.div`
    padding: 20px 0;

    h3 {
      margin: 0;
    }
  `,
  Icon: styled.div<TProps>`
    ${({ iconPosition, strikethroughIcon }) => `
      display: flex;
      order: ${iconPosition === Position.LEFT ? '-1' : 'initial'};
      position: relative;

      &:after {
        background-color: #ff4040;
        content: '';
        display: ${strikethroughIcon ? 'block' : 'none'};
        height: 4px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%,-50%) rotate(45deg);
        width: calc(100% + 4px);
      }

      img {
        align-self: stretch;
        height: auto;
        max-height: 100%;
        min-height: auto;
      }
    `}
  `,
}
