import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { createPortal } from 'react-dom'

import { AppWithInAppBrowser, PlatformOS } from '~/shared/constants'
import {
  getAppNameFromInAppBrowser,
  getMobileOperatingSystem,
} from '~/shared/utils'

import { StyledOuterLinkIframe } from './OuterLink.styles'

type TProps = {
  href: string
  children?: React.ReactNode
  className?: string
  onClick?: (event: SyntheticEvent<HTMLAnchorElement>) => void
}

export const OuterLink: React.FC<TProps> = ({
  href,
  onClick,
  children,
  className,
}) => {
  const [isIframeShown, setIsIframeShown] = useState(false)

  const isAndroidInstagramOrFb = useMemo(() => {
    const appName = getAppNameFromInAppBrowser()
    const isInstagramOrFb =
      appName === AppWithInAppBrowser.FACEBOOK ||
      appName === AppWithInAppBrowser.INSTAGRAM
    const isAndroid = getMobileOperatingSystem() === PlatformOS.ANDROID

    return isInstagramOrFb && isAndroid
  }, [])

  useEffect(() => {
    if (!isAndroidInstagramOrFb) return

    const backButtonEventCallback = () => setIsIframeShown(false)

    window.addEventListener('popstate', backButtonEventCallback)

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('popstate', backButtonEventCallback)
    }
  }, [isAndroidInstagramOrFb])

  const handleClick = useCallback(
    (e: SyntheticEvent<HTMLAnchorElement>) => {
      onClick?.(e)

      if (isAndroidInstagramOrFb) {
        e.preventDefault()
        setIsIframeShown(true)
      }
    },
    [isAndroidInstagramOrFb, onClick],
  )

  return (
    <>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={href}
        className={className}
        onClick={handleClick}
      >
        {children}
      </a>
      {isIframeShown &&
        isAndroidInstagramOrFb &&
        createPortal(
          <StyledOuterLinkIframe
            title="outer link"
            src={href}
            frameBorder="0"
          />,
          document.querySelector('body') as HTMLElement,
        )}
    </>
  )
}
