import { useSelector as useReduxSelector } from 'react-redux'

import { IAppState } from '~/shared/store/types'

type TSelector<T> = (state: IAppState) => T
type TSelectorMap = Record<string, TSelector<any>>

type TInferSelectorMapReturn<T extends TSelectorMap> = {
  [K in keyof T]: ReturnType<T[K]>
}

export const useBulkSelector = <T extends TSelectorMap>(
  selectors: T,
): TInferSelectorMapReturn<T> => {
  return useReduxSelector((state: IAppState) => {
    const selectorEntries = Object.entries(selectors)

    return selectorEntries.reduce((results, [selectorKey, selectorFn]) => {
      const currentValue = selectorFn(state)

      return {
        ...results,
        [selectorKey]: currentValue,
      }
    }, {} as TInferSelectorMapReturn<T>)
  })
}
