import React, { Suspense, useRef } from 'react'

import { useLocation } from 'react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import { Spinner } from '~/shared/components/Spinner'

type TProps = {
  children?: React.ReactNode
}

export const AnimationProvider: React.FC<TProps> = ({ children }) => {
  const location = useLocation()
  const nodeRef = useRef(null)

  if (location.pathname === '/') {
    return <Suspense fallback={<Spinner />}>{children}</Suspense>
  }

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={location.pathname}
        nodeRef={nodeRef}
        timeout={100}
        classNames="fade"
      >
        {() => (
          <div ref={nodeRef}>
            <Suspense>{children}</Suspense>
          </div>
        )}
      </CSSTransition>
    </SwitchTransition>
  )
}
