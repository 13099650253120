export const NUMERIC_KEYPAD_REGEX = /([0-9]{1}|Backspace|Enter)/
export const NUMERIC_KEYPAD_FLOAT_REGEX = /([0-9.,]{1}|Backspace|Enter)/
export const FLOAT_NUMBER_REGEX = /^([1-9]+[0-9]*|0{1})([.,][0-9]{0,1})?$/
export const ALPHABETIC_SYMBOLS_REGEXP = /[^\p{L}\p{Z}]+/gu
export const PERSONALIZATION_PLACEHOLDER_REGEXP = /{{(.*?)}}/g
export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const INTEGER_NUMBER_REGEX = /^[1-9]+[0-9]*$/
export const CARDHOLDER_NAME_REGEX =
  /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/
