import { LoginMethod } from '~/shared/constants'
import { IUserStatus, IUserStatusRaw } from '~/shared/types'

const LOGIN_METHOD_RAW = {
  EMAIL: 'password',
  FACEBOOK: 'facebook.com',
  APPLE: 'apple.com',
} as const

const LOGIN_METHOD_RAW_MAP = {
  [LOGIN_METHOD_RAW.EMAIL]: LoginMethod.EMAIL,
}

export const getUserStatusFromRawUserStatus = (
  userStatusRaw: IUserStatusRaw,
): IUserStatus => ({
  countryCode: userStatusRaw.country_code,
  isOnboardingSkipped: userStatusRaw.onboarding.is_skipped,
  isOnboardingFinished: userStatusRaw.onboarding.is_finished,
  onboarding: userStatusRaw.onboarding.quiz,
  config: {
    paymentCurrency: userStatusRaw.config?.payment_currency || '',
    paymentMethod: userStatusRaw.config?.payment_method || '',
    isDownloadVisited: userStatusRaw.config?.is_download_visited || false,
    discountApplied: userStatusRaw.config?.discount_applied || false,
    subscriptionPrice: userStatusRaw.config?.subscription_price || '',
    subscriptionDuration: userStatusRaw.config?.subscription_duration || '',
    priceId: userStatusRaw.config?.price_id || '',
    trialPeriod: userStatusRaw.config?.trial_period || '',
    trialPrice: userStatusRaw.config?.trial_price || '',
    utmTags: userStatusRaw.config?.utm_tags || null,
    availableUpsellPages: userStatusRaw.config?.available_upsell_pages || null,
    paymentId: userStatusRaw.config?.payment_id,
    paymentSystem: userStatusRaw.config?.payment_system || '',
    cookieConsent: userStatusRaw.config?.cookie_consent,
  },
  email: {
    hasEmail: userStatusRaw.email.has_email,
    hasEmailConsent: userStatusRaw.email.has_email_consent,
    isEmailConsentTried: userStatusRaw.email.is_email_consent_tried,
    onboardingEmail: userStatusRaw.email.onboarding_email,
  },
  hasSubscription: userStatusRaw.subscription.has_subscription,
  account: {
    hasAccount: userStatusRaw.account.has_account,
    loginMethod:
      LOGIN_METHOD_RAW_MAP[userStatusRaw.account?.login_method] || null,
    email: userStatusRaw.account.identifier,
  },
  upsell: {
    isUpsellPurchased: userStatusRaw.upsell.is_upsell_purchased,
    isUpsellAvailable: userStatusRaw.upsell.is_upsell_available,
    isUpsellTried: userStatusRaw.upsell.is_upsell_tried,
  },
  inapp: {
    isInAppAvailable: userStatusRaw.inapp.is_inapp_available,
    isInAppPurchased: userStatusRaw.inapp.is_inapp_purchased,
    isInAppTried: userStatusRaw.inapp.is_inapp_tried,
  },
})
