import { Events, RequestMethod } from '~/shared/constants'
import { ApiService } from '~/shared/services'
import { getCurrentEnvironment } from '~/shared/utils'

const btoaAuthCredentialsProd =
  'cHJvZC1mYWNlLXlvZ2Etd3Nob29rc3VzZXI6WXFCNH1tYVJvMQ=='
const btoaAuthCredentialsDev =
  'ZGV2LWZhY2UteW9nYS13c2hvb2tzdXNlcjptWUpTbWdtM3Vn'

export class EventsApi {
  private readonly api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  async sendEvent(
    event: Events,
    eventProperty: Record<string, any>,
    callback?: () => void,
  ): Promise<void> {
    const options = {
      method: RequestMethod.POST,
      headers: new Headers({
        Authorization: `Basic ${this.getBtoaAuthCredentials()}`,
      }),
      body: { event, ...eventProperty },
    }

    await this.api.makeRequest<never>('v1/events/landing', options)

    callback?.()
  }

  private getBtoaAuthCredentials = (): string =>
    getCurrentEnvironment().isProdEnvironment
      ? btoaAuthCredentialsProd
      : btoaAuthCredentialsDev
}
