import { API_APP_NAME, Cohort, RequestMethod } from '~/shared/constants'
import { ApiService } from '~/shared/services'
import {
  IAppConfigResponseRaw,
  IPaymentConfigRaw,
  IRequestOptions,
  IResponseResult,
} from '~/shared/types'

export class ConfigApi {
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  getAppConfig(): Promise<IResponseResult<IAppConfigResponseRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
    }

    return this.api.makeRequest<IAppConfigResponseRaw>(
      `v1/flow/${API_APP_NAME}/config`,
      options,
    )
  }

  getPaymentConfig({
    cohort,
    uuid,
  }: {
    cohort: Cohort
    uuid: string
  }): Promise<IResponseResult<IPaymentConfigRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
      params: { uuid },
    }

    return this.api.makeRequest<IPaymentConfigRaw>(
      `v1/flow/${API_APP_NAME}/variant/${cohort}/payment-config`,
      options,
    )
  }
}
