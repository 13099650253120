import { ApiService } from '~/shared/services'

import { ConfigApi } from './configApi'
import { EventsApi } from './eventsApi'
import { PaymentApi } from './paymentApi'
import { SubscriptionsApi } from './subscriptionsApi'
import { UserApi } from './userApi'
import { VariantsApi } from './variantsApi'

/**
 * API_PREFIX - declare in webpack.config.js as a global variable
 */
declare const API_PREFIX: string

export const baseApiService = new ApiService(API_PREFIX)
export const hooksApiService = new ApiService('/hooks')

export const paymentApi = new PaymentApi(baseApiService)
export const userApi = new UserApi(baseApiService)
export const eventsApi = new EventsApi(hooksApiService)
export const variantsApi = new VariantsApi(baseApiService)
export const configApi = new ConfigApi(baseApiService)
export const subscriptionsApi = new SubscriptionsApi(baseApiService)
