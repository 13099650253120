import { PageId } from './pageConstants'

export const enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
}

export const CDN_FOLDER_LINK =
  'https://cdn-prod-unified-content.gismart.xyz/d5808ebedf5357929eee86647da0a1d76abe111a9f5d3503d7eb9157aad897bd'

export const enum LoginMethod {
  EMAIL = 'email',
}

export const INITIAL_INPUT_VALUE = {
  value: '',
  isValid: false,
}

export const enum FirebaseSignUpErrorCode {
  EMAIL_EXISTS = 'auth/email-already-in-use',
}

export const enum TimeInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export const enum UpsellProduct {
  BOTH = 'pdf_FitnessAndSelfcare',
  BOTH_RECURRENT = 'pdf_FitnessAndSelfcare_rec',
  FITNESS = 'pdf_fitness',
  FITNESS_RECURRENT = 'pdf_fitness_rec',
  SELF_CARE = 'pdf_selfcare',
  SELF_CARE_RECURRENT = 'pdf_selfcare_rec',
  SIMPLE_CHANGES = 'pdf_SimpleChanges',
  SUGAR_FREE = 'pdf_SugarFreeDesserts',
  CHANGES_SUGAR = 'pdf_WeightLossGuides',
}

export const enum PlatformOS {
  ANDROID = 'Android',
  IOS = 'iOS',
  UNKNOWN = 'unknown',
}

export const enum AppWithInAppBrowser {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
}

export const enum SubscriptionListType {
  PURCHASE = 'purchase',
  UPSELL = 'upsell',
  ONE_TIME = 'one_time',
}

export const enum SubscriptionTags {
  TAX = 'tax',
  NO_TAX = 'no_tax',
  CANCEL_OFFER = 'cancel',
  UPSELL_LONG = 'upsell_long',
  INAPP_FITNESS_SELFCARE = 'inapp_fitness_selfcare',
  INAPP_SELFCARE = 'inapp_selfcare',
  INAPP_FITNESS = 'inapp_fitness',
  INAPP_CHANGES_DESSERTS = 'inapp_changes_desserts',
}

export const enum MeasurementSystem {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}

export const enum PlanAddition {
  MEAL_PLAN = 'Meal plan',
  PREMIUM = 'Premium',
  UPSELL = 'Upsell',
}

export const enum PurchaseProductDuration {
  SEVEN_DAY = '7day',
}

export const enum PurchasePageType {
  WEB_LANDING = 'web_landing',
  EMAIL_LANDING = 'email_landing',
}

export const enum Period {
  SEVEN_DAYS = 7,
}

export const enum AppLinks {
  TOU_LINK = `/${PageId.TERMS_OF_USE}`,
  PP_LINK = `/${PageId.PRIVACY_POLICY}`,
}

export const FEATURE_KEYS = {
  fas_530_new_upsell: 'fas_530_new_upsell',
  fas_801_dynamic_discount: 'fas_801_dynamic_discount',
  fas_1055_checkout_in_modal: 'fas_1055_checkout_in_modal',
  fas_807_primer_card: 'fas_807_primer-card',
  fas_1229_recurring_1st_upsell: 'fas_1229_recurring_1st_upsell',
  fas_1299_checkout_timer: 'fas_1299_checkout-timer',
} as const

export const MIN_MAX_WEIGHT = {
  MIN_WEIGHT_KG: 21,
  MAX_WEIGHT_KG: 149,
  MIN_WEIGHT_LB: 46,
  MAX_WEIGHT_LB: 328,
}
export const MIN_KG_DIFF = 1
export const MIN_LB_DIFF = 2

export const TIMER_SECONDS_MAP = {
  [PageId.KIT_PURCHASE_EMAIL_1]: 900,
  [PageId.KIT_PURCHASE_EMAIL_2]: 900,
}

export const enum OptionType {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
}

export const API_APP_NAME = 'myfast'
export const CUSTOM_TOKEN_LOCAL_STORAGE_KEY = 'custom_token'
export const TEST_ENV_QUERY_PARAM = 'is_test_environment'
export const MAXIMUM_WEIGHT_LOSS_PERCENTAGE = 0.00143
export const LOWER_BMI_THRESHOLD_V1 = 10
export const UPPER_BMI_THRESHOLD = 45
export const LANG_QUERY_PARAM = 'lang'
export const CHECKBOX_CUSTOM_VALUES = ['none']
export const PERCENTAGE_MULTIPLIER = 100
export const TOU_AND_PP_LINKS: string[] = [AppLinks.TOU_LINK, AppLinks.PP_LINK]
export const INFLUENCER_NAME_SESSION_STORAGE_KEY = 'influencer_name'
export const DEFAULT_TIMER_CONTENT = '00'
export const TOTAL_USERS = 1_100_000
export const MIN_AGE = 18
export const MAX_AGE = 100
export const TEN_MINUTES_IN_SECONDS = 600
