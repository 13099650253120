import React from 'react'

import logoPng from '~/shared/assets/images/logo.png'

import { StyledLogo as S } from './Logo.styles'

export type TLogoProps = {
  marginBottom?: number
  className?: string
}

const APP_NAME = 'NutriMate'

export const Logo: React.FC<TLogoProps> = ({
  marginBottom = 32,
  className,
}) => (
  <S.Wrapper className={className} marginBottom={marginBottom}>
    <S.LogoContainer>
      <S.Logo>
        <img src={logoPng} alt={APP_NAME} width={24} height={24} />
      </S.Logo>
      <S.LogoText>{APP_NAME}</S.LogoText>
    </S.LogoContainer>
  </S.Wrapper>
)
