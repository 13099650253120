import { useSelector } from 'react-redux'

import { VAT_INCLUDED_COUNTRIES } from '~/shared/constants'
import { selectUserCountryCode } from '~/shared/store/user'

export const useVatInfo = () => {
  const countryCode = useSelector(selectUserCountryCode)

  return VAT_INCLUDED_COUNTRIES.includes(countryCode)
}
