export const enum Currency {
  USD = 'usd',
  EUR = 'eur',
  GBP = 'gbp',
  NZD = 'nzd',
  AUD = 'aud',
  CAD = 'cad',
  MXN = 'mxn',
  BRL = 'brl',
  JPY = 'jpy',
  PLN = 'pln',
}

export const CURRENCY_SYMBOLS = {
  [Currency.USD]: '$',
  [Currency.EUR]: '€',
  [Currency.GBP]: '£',
  [Currency.NZD]: 'NZ$',
  [Currency.AUD]: 'AU$',
  [Currency.CAD]: 'C$',
  [Currency.MXN]: 'MXN',
  [Currency.BRL]: 'R$',
  [Currency.JPY]: '¥',
  [Currency.PLN]: 'zł',
}

export const CURRENCY_NAMES = {
  [Currency.USD]: 'dollars',
  [Currency.EUR]: 'euros',
  [Currency.GBP]: 'pounds',
  [Currency.NZD]: 'New Zealand dollars',
  [Currency.AUD]: 'Australian dollars',
  [Currency.CAD]: 'Canadian dollars',
  [Currency.MXN]: 'Mexican pesos',
  [Currency.BRL]: 'reais',
  [Currency.JPY]: 'yen',
  [Currency.PLN]: 'zlotys',
}

export const CURRENCY_SUBUNIT_NAMES = {
  [Currency.USD]: 'cents',
  [Currency.EUR]: 'cents',
  [Currency.GBP]: 'pence',
  [Currency.NZD]: 'cents',
  [Currency.AUD]: 'cents',
  [Currency.CAD]: 'cents',
  [Currency.MXN]: 'centavos',
  [Currency.BRL]: 'centavos',
  [Currency.JPY]: null,
  [Currency.PLN]: 'groszy',
}

export const ZERO_DECIMAL_CURRENCIES = [
  'gnf',
  'pyg',
  'rwf',
  'jpy',
  'bif',
  'kmf',
  'krw',
  'clp',
  'mga',
  'ugx',
  'djf',
  'vnd',
  'vuv',
  'xaf',
  'xof',
  'xpf',
]
