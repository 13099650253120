import styled, { css } from 'styled-components'

import { Color } from '~/shared/constants'

import { IProps as IButtonProps } from './Button'

export const StyledButtonBase = css`
  width: 100%;
  max-width: 328px;
  height: 56px;
  display: block;
  margin: 0 auto;
  padding: 0 16px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: ${Color.PRIMARY};
  border-radius: 16px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
  text-align: center;
  color: ${Color.WHITE};
  transition: background-color 0.2s ease-out;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`

export const StyledButton = styled.button<IButtonProps>`
  ${StyledButtonBase};

  &:disabled {
    background-color: ${({ disabledBackgroundColor }) =>
      disabledBackgroundColor || '#e3e4e8'};
  }
`
