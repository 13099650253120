export const enum MediaBreakpoint {
  MIN_PHONE = 321,
  MEDIUM_PHONE = 360,
  MAX_PHONE = 440,
  TABLET = 520,
  MAX_TABLET = 768,
  MIN_LAPTOP = 1024,
  LAPTOP = 1800,
  MAX_PHONE_HEIGHT = 667,
}

export const enum Color {
  PRIMARY = '#6fd09a',
  SECONDARY = '#e8ffee',
  LIGHT_GRAY = '#c4c4c4',
  SUCCESS = '#7ad718',
  ERROR = '#ff6169',
  WHITE = '#fff',
  GREY_WHITE = '#e9ecee',
  BLACK = '#17202a',
  BLACK_100 = '#000',
  BLACK_200 = '#2d3240',
  BLACK_300 = '#151920',
  BLACK_400 = '#292d3d',
  BLACK_500 = '#333a49',
  LIGHT = '#f5f6f7',
  LIGHT_BLUE = '#edeff0',
  DANGER = '#ff8484',
  DISABLED = '#898e97',
  ORANGE = '#f08b34',
  YELLOW_100 = '#fcd903',
  YELLOW_200 = '#efd701',
  ORANGE_100 = '#eaa300',
  ORANGE_200 = '#ff9900',
  VERY_LIGHT_BLUE = '#f7f9fc',
  DARK_BLUE = '#131d30',
  PINK = '#e0698c',
  PALE_RED = '#fff2f2',
  ORANGE_LIGHT = '#fff8f2',
  BLUE = '#978fff',
  GREY = '#d5d6d6',
  GREY_100 = '#2c3131',
  GREY_101 = '#626262',
  GREY_102 = '#a9a9a9',
  GREY_104 = '#2f3643',
  GREY_110 = '#647069',
  GREY_111 = '#b0b4bc',
  GREY_120 = '#f1f5f9',
  GREY_130 = '#f2f3f5',
  GREY_200 = '#f1f1f3',
  GREY_300 = '#d1d5db',
  GREY_400 = '#e5e7eb',
  GREY_500 = '#b8bbc1',
  GREY_600 = '#81848c',
  GREY_700 = '#fafaff',
  GREY_750 = '#E8E8E8',
  GREY_800 = '#e9e9e9',
  GREY_900 = '#efeff6',
  GREY_1000 = '#cacaca',
  GREEN_100 = '#f1fff8',
  GREEN_200 = '#d1fae5',
  GREEN_300 = '#98bf36',
  GREEN_400 = '#a9cd50',
  GREEN_550 = '#F1F7E3',
  GREEN_600 = '#eef5dc',
  GREEN_700 = '#f2f6ee',
  GREEN_750 = '#90b632',
  GREEN_800 = '#7ba60f',
  RED_100 = '#bc2222',
  RED_200 = '#ff4329',
  RED_300 = '#f83b00',
  RED_400 = '#e74c3c',
  RED_500 = '#ed8e70',
}

export const enum DynamicDiscountThemeValue {
  ORANGE = 'orange',
  VIOLET = 'violet',
  RED = 'red',
  BLACK = 'black',
}

export const enum Gradient {
  RADIAL_RED_YELLOW = '104% 760.5% at 15.36% 124%,#ff0031 0%,#ffbc14 100%',
  LINEAR_RED_YELLOW = '73deg, #ff0031 -1.71%, #ffbe1b 111.15%',
  LINEAR_RED_YELLOW_LIGHT = '88deg, #FBD3DE -0.53%, #FBEDDB 108.08%',
  LINEAR_RED_CORAL_LIGHT = '87deg, #F2D6DA -4.33%, #FAE0E4 111.17%',
  LINEAR_RED_CORAL = '73deg, #C40202 -1.71%, #FF5659 111.15%',
  LINEAR_VIOLET_BLUE = '73deg, #7B00FF -1.71%, #1BE4FF 111.15%',
  LINEAR_VIOLET_BLUE_LIGHT = '87deg, #E6D6FF -3.7%, #DAF6FE 119.47%',
}

export const enum Emojis {
  BICEPS = '\uD83D\uDCAA',
  SOUP = '\uD83C\uDF72',
  SUPPORT = '\uD83E\uDEC2',
  CHART = '\uD83D\uDCC8',
  FINGER_DOWN_POINTER = '\uD83D\uDC47',
  FINGER_UP_POINTER = '\u261d\ufe0f',
  STAR_EYES = '\uD83E\uDD29',
  STAR = '\u2b50',
  WAVING_HAND = '\uD83D\uDC4B',
  SMILING_FACE_WITH_3_HEARTS = '\uD83E\uDD70',
  OK_HAND = '\uD83D\uDC4C',
  THUMBS_UP = '\uD83D\uDC4D',
  PARTY = '\ud83c\udf89',
}

export const enum Position {
  LEFT = 'left',
  RIGHT = 'right',
}

export const MAX_COLUMN_WIDTH = 360
export const KIT_MAX_COLUMN_WIDTH = 375
export const PAGE_HORIZONTAL_PADDING = 16
export const HEADER_HEIGHT_WITHOUT_PROGRESSBAR = 44
export const MODAL_OUTER_INDENT = 40
