import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import { useSelector } from 'react-redux'

import { Cohort, FEATURE_KEYS, PageId } from '~/shared/constants'
import { selectCurrentVariantCohort } from '~/shared/store/common'

export const useExperimentalFeatures = () => {
  const cohort = useSelector(selectCurrentVariantCohort)

  const isAlternativeDiscountVariant = useFeatureIsOn(
    FEATURE_KEYS.fas_801_dynamic_discount,
  )
  const isAlternativeCheckoutVariant = useFeatureIsOn(
    FEATURE_KEYS.fas_1055_checkout_in_modal,
  )

  const isRecurringFirstUpsellFeature = useFeatureIsOn(
    FEATURE_KEYS.fas_1229_recurring_1st_upsell,
  )
  const isPrimerCard = useFeatureIsOn(FEATURE_KEYS.fas_807_primer_card)
  const isAlternativeUpsell = useFeatureIsOn(FEATURE_KEYS.fas_530_new_upsell)
  const isCheckoutWithTimer =
    JSON.parse(
      useFeatureValue(
        FEATURE_KEYS.fas_1299_checkout_timer,
        `[${PageId.KIT_CHECKOUT_3}]`,
      ),
    )[0] === PageId.KIT_CHECKOUT_1

  return {
    isAlternativeDiscountVariant,
    isAlternativeCheckoutVariant,
    isAlternativeUpsell,
    isPrimerCard,
    isRecurringFirstUpsell:
      cohort === Cohort.MF_INTRO_2_TT ? true : isRecurringFirstUpsellFeature,
    isCheckoutWithTimer,
  }
}
