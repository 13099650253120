export const enum Language {
  EN = 'en',
  ES = 'es',
  PT = 'pt',
  FR = 'fr',
  DE = 'de',
  JA = 'ja',
  PL = 'pl',
  IN_CONTEXT = 'af',
}

export const LANGUAGE_STRIPE_LOCALE_MAP = {
  [Language.EN]: 'GB',
  [Language.ES]: 'ES',
  [Language.PT]: 'PT',
  [Language.FR]: 'FR',
  [Language.DE]: 'DE',
  [Language.JA]: 'JP',
  [Language.PL]: 'PL',
}

export const SUPPORTED_LOCALES: string[] = [
  Language.EN,
  Language.ES,
  Language.PT,
  Language.JA,
  Language.FR,
  Language.DE,
  Language.PL,
]

export const INITIAL_LANGUAGE = Language.EN
