import { useCallback } from 'react'

import { useDispatch } from 'react-redux'

import { eventLogger } from '~/shared/services'
import { setAnswersAction } from '~/shared/store/common'
import { sendUserAnswersAction } from '~/shared/store/user'
import { IStepInfo, TAnswer } from '~/shared/types'
import { appHistory } from '~/shared/utils'

export const useNextStep = ({
  pageId,
  question,
  nextPagePath,
  isFirstPage,
}: IStepInfo): ((value: TAnswer, designation?: TAnswer) => void) => {
  const dispatch = useDispatch()

  return useCallback(
    (value: TAnswer, designation?: TAnswer) => {
      dispatch(
        setAnswersAction({
          answers: value,
          pageId,
        }),
      )
      dispatch(sendUserAnswersAction())
      eventLogger.logQuestion({
        question,
        answers: designation ? `${value},${designation}` : value,
      })

      if (isFirstPage) {
        eventLogger.logFirstPageCompleted({
          question,
          answers: value,
        })
      }

      appHistory.goTo(nextPagePath)
    },
    [dispatch, question, pageId, nextPagePath],
  )
}
