export const COUNTRIES_WITH_IMPERIAL_MEASUREMENT_SYSTEM = [
  'AU',
  'CA',
  'NZ',
  'US',
  'ZA',
]

export const enum CountryCode {
  ARGENTINA = 'ar',
  COLOMBIA = 'co',
  PERU = 'pe',
  CHILE = 'cl',
  DOMINICAN_REPUBLIC = 'do',
  URUGUAY = 'uy',
  ECUADOR = 'ec',
  GUATEMALA = 'gt',
  VENEZUELA = 've',
  PARAGUAY = 'py',
  BOLIVIA = 'bo',
  NICARAGUA = 'ni',
  HONDURAS = 'hn',
  COSTA_RICA = 'cr',
  PANAMA = 'pa',
  EL_SALVADOR = 'sv',
  BRAZIL = 'br',
  INDIA = 'in',
  SOUTH_AFRICA = 'za',
  NIGERIA = 'ng',
  KENYA = 'ke',
  ZAMBIA = 'zm',
  TANZANIA = 'tz',
  ETHIOPIA = 'et',
  ZIMBABWE = 'zw',
  SENEGAL = 'sn',
  NAMIBIA = 'na',
  POLAND = 'pl',
}

export const COUNTRIES_WITHOUT_PAYPAL: string[] = [
  CountryCode.ARGENTINA,
  CountryCode.COLOMBIA,
  CountryCode.PERU,
  CountryCode.CHILE,
  CountryCode.DOMINICAN_REPUBLIC,
  CountryCode.URUGUAY,
  CountryCode.ECUADOR,
  CountryCode.GUATEMALA,
  CountryCode.VENEZUELA,
  CountryCode.PARAGUAY,
  CountryCode.BOLIVIA,
  CountryCode.NICARAGUA,
  CountryCode.HONDURAS,
  CountryCode.COSTA_RICA,
  CountryCode.PANAMA,
  CountryCode.EL_SALVADOR,
  CountryCode.BRAZIL,
  CountryCode.INDIA,
  CountryCode.SOUTH_AFRICA,
  CountryCode.NIGERIA,
  CountryCode.KENYA,
  CountryCode.ZAMBIA,
  CountryCode.TANZANIA,
  CountryCode.ETHIOPIA,
  CountryCode.ZIMBABWE,
  CountryCode.SENEGAL,
  CountryCode.NAMIBIA,
  CountryCode.POLAND,
]

export const VAT_INCLUDED_COUNTRIES = ['US', 'MX', 'ES', 'BR', 'FR', 'DE', 'PL']
