import React from 'react'

import { Trans } from 'react-i18next'

import { OuterLink } from '~/shared/components/OuterLink'
import { AppLinks } from '~/shared/constants'
import { eventLogger } from '~/shared/services'

export const PrivacyPolicyLink: React.FC = () => (
  <OuterLink
    href={AppLinks.PP_LINK}
    onClick={() => eventLogger.logPrivacyPolicyClicked()}
  >
    <Trans i18nKey="commonComponents.privacyPolicy" />
  </OuterLink>
)
