import { LANG_QUERY_PARAM, TEST_ENV_QUERY_PARAM } from '~/shared/constants'

type TPagePathConfig = {
  pageId: string
  cohort: string
  uuid: string
  language: string
  testEnvironmentQueryParam: string
  currentSearch: string
}

export const getPathFromPageId = ({
  pageId,
  cohort,
  uuid,
  language,
  testEnvironmentQueryParam,
  currentSearch,
}: TPagePathConfig) => {
  const searchParams = new URLSearchParams(currentSearch)

  searchParams.set('cohort', cohort)
  searchParams.set('uuid', uuid)
  searchParams.set(LANG_QUERY_PARAM, language)
  searchParams.delete(TEST_ENV_QUERY_PARAM)

  return `${pageId}?${searchParams.toString()}${testEnvironmentQueryParam}`
}
