import { createIntroOfferProductId } from '~/shared/helpers/createIntroOfferProductId'
import { createProductId } from '~/shared/helpers/createProductId'
import {
  selectScreenName,
  selectStripeAccountId,
  selectStripeAccountName,
} from '~/shared/store/common'
import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionLookupKey,
  selectSubscriptionPeriodName,
  selectSubscriptionPeriodQuantity,
  selectSubscriptionTrialLookupKey,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from '~/shared/store/payment'
import { IAppState } from '~/shared/store/types'
import {
  selectIsPersonalDataAllowed,
  selectUUID,
  selectUserOnboardingEmail,
  selectUserPaymentSystem,
} from '~/shared/store/user'

const getPurchaseCommonEventParams = (state: IAppState) => {
  const uuid = selectUUID(state)
  const stripePriceId = selectSubscriptionLookupKey(state)
  const trialPriceId = selectSubscriptionTrialLookupKey(state)
  const trialPeriodQuantity = selectSubscriptionTrialPeriodDays(state)
  const price = selectSubscriptionFullPrice(state)
  const trialPeriodPrice = selectSubscriptionTrialPeriodPrice(state)
  const periodName = selectSubscriptionPeriodName(state)
  const periodQuantity = selectSubscriptionPeriodQuantity(state)
  const currency = selectCurrency(state)
  const screenName = selectScreenName(state)
  const stripeAccountId = selectStripeAccountId(state)
  const stripeAccountName = selectStripeAccountName(state)
  const paymentSystem = selectUserPaymentSystem(state)
  const isPersonalDataAllowed = selectIsPersonalDataAllowed(state)

  const productId = trialPriceId
    ? createIntroOfferProductId({
        priceId: stripePriceId,
        trialPriceId,
        trialPeriodQuantity,
      })
    : createProductId({
        periodName,
        periodQuantity,
        price,
      })

  return {
    uuid,
    price,
    trialPeriodPrice,
    periodName,
    periodQuantity,
    currency,
    trialPriceId,
    screenName,
    productId,
    stripePriceId,
    stripeAccountId,
    stripeAccountName,
    paymentSystem,
    isPersonalDataAllowed,
  }
}

export const getPurchaseFailedEventParams = (state: IAppState) => {
  const {
    productId,
    price,
    trialPeriodPrice,
    currency,
    screenName,
    stripeAccountId,
    stripeAccountName,
    paymentSystem,
  } = getPurchaseCommonEventParams(state)

  return {
    productId,
    priceDetails: {
      price,
      trial: !!trialPeriodPrice,
      currency,
    },
    screenName,
    stripeAccountId,
    stripeAccountName,
    paymentSystem,
  }
}

export const getPurchaseStartedEventParams = (state: IAppState) => {
  const {
    productId,
    price,
    trialPeriodPrice,
    currency,
    screenName,
    stripePriceId,
    trialPriceId,
    stripeAccountId,
    stripeAccountName,
    paymentSystem,
  } = getPurchaseCommonEventParams(state)

  return {
    productId: trialPriceId ? productId : stripePriceId,
    priceDetails: {
      price,
      trial: !!trialPeriodPrice,
      currency,
    },
    screenName,
    stripeAccountName,
    stripeAccountId,
    paymentSystem,
  }
}

export const getPurchaseSuccessEventParams = (state: IAppState) => {
  const {
    trialPeriodPrice,
    trialPriceId,
    uuid,
    periodName,
    periodQuantity,
    currency,
    screenName,
    stripeAccountId,
    stripeAccountName,
    paymentSystem,
    price,
    productId,
    isPersonalDataAllowed,
  } = getPurchaseCommonEventParams(state)
  const email = selectUserOnboardingEmail(state)

  return {
    trialPrice: trialPeriodPrice,
    trialPriceId,
    uuid,
    periodName,
    periodQuantity,
    currency,
    screenName,
    email,
    stripeAccountName,
    stripeAccountId,
    paymentSystem,
    price,
    productId,
    isPersonalDataAllowed,
  }
}
