import {
  MeasurementSystem,
  PlanAddition,
  UPPER_BMI_THRESHOLD,
} from './commonConstants'
import { PageId } from './pageConstants'

export const enum AgeRangeValue {
  AR_18_25 = '18-25',
  AR_18_29 = '18-29',
  AR_26_35 = '26-35',
  AR_30_39 = '30-39',
  AR_36_45 = '36-45',
  AR_46_PLUS = '46+',
  AR_40_49 = '40-49',
  AR_45_49 = '45-49',
  AR_50_54 = '50-54',
  AR_54_59 = '54-59',
  AR_50_PLUS = '50+',
  AR_60_PLUS = '60+',
}

export enum ActivityDuringWorkValue {
  PASSIVE = 'passive',
  ACTIVE = 'active',
  NORMAL = 'normal',
}

export const enum BmiValue {
  UNDERWEIGHT = 'underweight',
  NORMAL = 'normal',
  OVERWEIGHT = 'overweight',
  OBESITY = 'obesity',
  EXTREME_OBESITY = 'extreme_obesity',
}

export const enum BodyPartValue {
  ARMS = 'arms',
  CHEST = 'chest',
  BELLY = 'belly',
  HIPS = 'hips',
  LEGS = 'legs',
}

export const enum FavouriteFoodsValue {
  MEAT = `I'm a meat eater`,
  FISH = 'Fish is my favorite',
  FRUITS_AND_VEGGIES = 'I love fruits and veggies',
  CARBS = 'Carbs day and night (pasta, rice, bread, etc.)',
  CHEESE = `I can't live without cheese`,
}

export const enum FastingValue {
  NOTHING = 'Nothing at all',
  HEARD = `I've heard about it`,
  EXPERIENCED = `I'm an experienced faster`,
}

export const enum MotivationValue {
  VACATION = `vacation`,
  WEDDING = `wedding`,
  SPORTS = `sporting_event`,
  SUMMER = `summer`,
  REUNION = `reunion`,
  FAMILY = `family_event`,
  HOLIDAY = `holiday`,
  OTHER = `other`,
  NO = `i_don't_have_events`,
  MORE_ATTRACTIVE = 'be_more_attractive',
  MORE_ENERGY = 'have_more_energy',
  NICE_CLOTHES = 'fit_into_nice_clothes',
  IDEAL_WEIGHT = 'return_my_ideal_weight',
}

export const enum PeriodValue {
  MENSTRUATION = 'Menstruation',
  PERIMENOPAUSE_MENOPAUSEE = 'Perimenopause or menopause',
}

export const enum PrimaryGoalValue {
  LOSE_WEIGHT = 'lose_weight',
  LOSE_BELLY_FAT = 'lose_belly_fat',
  SCULPT_MY_ABS = 'sculpt_my_abs',
  GET_FIT = 'get_fit',
  BUILD_HEALTHY_HABITS = 'build_healthy_habits',
  MASTER_INTUITIVE_EATING = 'master_intuitive_eating',
  GET_HEALTHY = 'get_healthy',
  BUILD_MUSCLE = 'build_muscle',
  SLOW_AGING = 'slow_aging',
  BOOST_ENERGY = 'boost_energy',
  SOMETHING_ELSE = 'something_else',
}

export const enum RestrictionsValue {
  NO_ANIMAL_PROTEIN = 'No animal protein',
  NO_LACTOSE = 'No lactose',
  NO_GLUTEN = 'No gluten',
  NO_PEANUTS = 'No peanuts',
  NO_SOY = 'No soy',
  NO_SHELLFISH = 'No shellfish',
}

export const enum WorkOutFrequencyValue {
  THREE_FIVE_A_WEEK = 'three_five_a_week',
  ONE_TWO_A_WEEK = 'one_two_a_week',
  RARELY = 'rarely',
  ALMOST_EVERY_DAY = 'almost_every_day',
  EVERY_DAY = 'every_day',
  SEVERAL_TIMES_PER_WEEK = 'several_times_per_week',
  SEVERAL_TIMES_PER_MONTH = 'several_times_per_month',
  NEVER = 'never',
}

export const enum IdealWeightTimeValue {
  LESS_THAN_YEAR_AGO = 'less_than_year_ago',
  ONE_TWO_YEARS_AGO = 'one_two_years_ago',
  MORE_THAN_THREE_YEARS_AGO = 'more_than_three_years_ago',
  NEVER = 'never',
}

export const enum SleepingTimeValue {
  LESS_THAN_FIVE = 'less_than_five',
  FIVE_SIX = 'five_six',
  SEVEN_EIGHT = 'seven_eight',
  MORE_THAN_EIGHT = 'more_than_eight',
}

export const enum BadHabitsValue {
  ENOUGH_REST = 'enough_rest',
  CHOCOLATE_AND_SWEETS = 'chocolate_and_sweets',
  SODAS = 'sodas',
  SALTY_FOODS = 'salty_foods',
  SNACK = 'snack',
  JUNK = 'junk',
  WHENEVER = 'whenever',
  OVEREAT = 'overeat',
  SAY_NO_TO_DRINK = 'say_no_to_drink',
  NONE = 'none',
}

export const enum Gender {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}

export const enum ActivityLevelValue {
  SEDENTARY = 'sedentary',
  SLIGHTLY_ACTIVE = 'slightly_active',
  MODERATELY = 'moderately',
  VERY_ACTIVE = 'very_active',
  SUPER_ACTIVE = 'super_active',
  LAZY = 'lazy',
  INACTIVE = 'inactive',
}

export const enum OnboardingButtonText {
  GET_PLAN_UPPER = 'get_plan_upper',
  GET_PLAN_MIDDLE = 'get_plan_middle',
  GIFT_CANCEL_OFFER = 'gift_cancel_offer',
  GET_PLAN_MIDDLE_2 = 'get_plan_middle2',
  GET_PLAN_BOTTOM = 'get_plan_bottom',
}

export const enum PurchaseProduct {
  SEVEN_DAY = 'sevenDay',
  ONE_MONTH = 'oneMonth',
  THREE_MONTHS = 'threeMonths',
  SIX_MONTHS = 'sixMonths',
}

export const TARGET_AREA_FULL_LIST = [
  BodyPartValue.ARMS,
  BodyPartValue.CHEST,
  BodyPartValue.BELLY,
  BodyPartValue.HIPS,
  BodyPartValue.LEGS,
]

export const BMI_LABELS_MAP = [
  {
    lowerThreshold: 10,
    upperThreshold: 18.5,
    value: BmiValue.UNDERWEIGHT,
  },
  {
    lowerThreshold: 18.5,
    upperThreshold: 24.99,
    value: BmiValue.NORMAL,
  },
  {
    lowerThreshold: 25,
    upperThreshold: 29.99,
    value: BmiValue.OVERWEIGHT,
  },
  {
    lowerThreshold: 30,
    upperThreshold: 34.99,
    value: BmiValue.OBESITY,
  },
  {
    lowerThreshold: 35,
    upperThreshold: UPPER_BMI_THRESHOLD,
    value: BmiValue.EXTREME_OBESITY,
  },
]

export const BODY_PART_NAME_MAP = {
  [BodyPartValue.ARMS]: 'commonComponents.bodyPart.arms',
  [BodyPartValue.CHEST]: 'commonComponents.bodyPart.chest',
  [BodyPartValue.BELLY]: 'commonComponents.bodyPart.belly',
  [BodyPartValue.HIPS]: 'commonComponents.bodyPart.hips',
  [BodyPartValue.LEGS]: 'commonComponents.bodyPart.legs',
}

export const PLAN_ADDITIONS_MAP: Record<string, PlanAddition[]> = {
  [PageId.PURCHASE_1]: [PlanAddition.PREMIUM],
  [PageId.PURCHASE_3]: [PlanAddition.PREMIUM],
  [PageId.PURCHASE_4]: [PlanAddition.PREMIUM],
  [PageId.UPSELL_PAYWALL_1]: [PlanAddition.UPSELL],
  [PageId.UPSELL_PAYWALL_2]: [PlanAddition.UPSELL],
}

export const PRIMARY_GOAL_MAP = {
  [PrimaryGoalValue.LOSE_WEIGHT]: 'commonComponents.primaryGoal.loseWeight',
  [PrimaryGoalValue.GET_FIT]: 'commonComponents.primaryGoal.getFit',
  [PrimaryGoalValue.BUILD_HEALTHY_HABITS]:
    'commonComponents.primaryGoal.developHealthyHabits',
  [PrimaryGoalValue.GET_HEALTHY]: 'commonComponents.primaryGoal.getHealthy',
  [PrimaryGoalValue.LOSE_BELLY_FAT]:
    'onboarding.primaryGoal.options.mf_belly.loseFat.title',
  [PrimaryGoalValue.SCULPT_MY_ABS]:
    'onboarding.primaryGoal.options.mf_belly.abs.title',
  [PrimaryGoalValue.MASTER_INTUITIVE_EATING]:
    'onboarding.primaryGoal.options.mf_belly.intuitiveEating.title',
}

export const MF_SCC_PRIMARY_GOAL_MAP = {
  [PrimaryGoalValue.BUILD_HEALTHY_HABITS]:
    'onboarding.primaryGoalV5.nutritionRoutine',
  [PrimaryGoalValue.LOSE_WEIGHT]: 'onboarding.primaryGoalV5.loseWeight',
  [PrimaryGoalValue.BUILD_MUSCLE]: 'onboarding.primaryGoalV5.buildMuscle',
  [PrimaryGoalValue.SLOW_AGING]: 'onboarding.primaryGoalV5.slowAging',
  [PrimaryGoalValue.BOOST_ENERGY]: 'onboarding.primaryGoalV5.boostEnergy',
  [PrimaryGoalValue.SOMETHING_ELSE]: 'onboarding.primaryGoalV5.loseWeight',
}

export const BMI_VALUE_TO_MARKUP_MAP = {
  [BmiValue.UNDERWEIGHT]: 'commonComponents.bmiValues.underweight',
  [BmiValue.NORMAL]: 'commonComponents.bmiValues.normal',
  [BmiValue.OVERWEIGHT]: 'commonComponents.bmiValues.overweight',
  [BmiValue.OBESITY]: 'commonComponents.bmiValues.obesity',
  [BmiValue.EXTREME_OBESITY]: 'commonComponents.bmiValues.extremeObesity',
}

export const ACTIVITY_DURING_WORK_MAP = {
  [ActivityDuringWorkValue.ACTIVE]: 'commonComponents.activity.active',
  [ActivityDuringWorkValue.PASSIVE]: 'commonComponents.activity.sedentary',
  [ActivityDuringWorkValue.NORMAL]: 'commonComponents.activity.moderately',
}

export const ACTIVITY_LEVEL_MAP = {
  [WorkOutFrequencyValue.THREE_FIVE_A_WEEK]:
    'commonComponents.activity.moderately',
  [WorkOutFrequencyValue.EVERY_DAY]: 'commonComponents.activity.active',
  [WorkOutFrequencyValue.RARELY]: 'commonComponents.activity.sedentary',
  [WorkOutFrequencyValue.NEVER]: 'commonComponents.activity.sedentary',
}

export const BELLY_ACTIVITY_LEVEL_MAP = {
  [WorkOutFrequencyValue.THREE_FIVE_A_WEEK]:
    'commonComponents.activity.moderately',
  [WorkOutFrequencyValue.EVERY_DAY]:
    'commonComponents.activity.mf_belly.active',
  [WorkOutFrequencyValue.RARELY]: 'commonComponents.activity.sedentary',
  [WorkOutFrequencyValue.NEVER]: 'commonComponents.activity.sedentary',
}

export const LOST_WEIGHT_PER_WEEK_MAP = {
  [BmiValue.UNDERWEIGHT]: {
    [MeasurementSystem.METRIC]: 1,
    [MeasurementSystem.IMPERIAL]: 2.2,
  },
  [BmiValue.NORMAL]: {
    [MeasurementSystem.METRIC]: 1,
    [MeasurementSystem.IMPERIAL]: 2.2,
  },
  [BmiValue.OVERWEIGHT]: {
    [MeasurementSystem.METRIC]: 1.5,
    [MeasurementSystem.IMPERIAL]: 3.3,
  },
  [BmiValue.OBESITY]: {
    [MeasurementSystem.METRIC]: 1.7,
    [MeasurementSystem.IMPERIAL]: 3.7,
  },
  [BmiValue.EXTREME_OBESITY]: {
    [MeasurementSystem.METRIC]: 2,
    [MeasurementSystem.IMPERIAL]: 4.4,
  },
}
