export const enum PaymentMethod {
  CREDIT_CARD = 'credit_card',
  ONE_CLICK = 'one_click',
  APPLE_PAY = 'apple_pay',
  GOOGLE_PAY = 'google_pay',
  PAYPAL = 'pay_pal',
}

export const enum PaymentSystem {
  PAYPAL = 'paypal',
  PRIMER = 'primer',
  STRIPE = 'stripe',
}

export const PRIMER_PAYMENT_ERRORS = {
  INSUFFICIENT_FUNDS: 'purchase.paymentErrors.insufficientFunds',
  CONNECTION_ERROR: 'purchase.paymentErrors.connectionError',
  PAYMENT_FAILED: 'purchase.paymentErrors.insufficientFunds',
  DECLINED: 'purchase.paymentErrors.declinedError',
  AUTHENTICATION_REQUIRED: 'purchase.paymentErrors.authenticationRequired',
  WITHDRAWAL_LIMIT_EXCEEDED: 'purchase.paymentErrors.withdrawLimit',
  EXPIRED_CARD: 'purchase.paymentErrors.expiredCard',
  COMMON_ERROR: 'purchase.paymentErrors.somethingWentWrongError',
  ERROR: 'purchase.paymentErrors.somethingWentWrongError',
  INVALID_CARD_NUMBER: 'purchase.paymentErrors.somethingWentWrongError',
  LOST_OR_STOLEN_CARD: 'purchase.paymentErrors.somethingWentWrongError',
  SUSPECTED_FRAUD: 'purchase.paymentErrors.somethingWentWrongError',
  UNKNOWN: 'purchase.paymentErrors.somethingWentWrongError',
  REFER_TO_CARD_ISSUER: 'purchase.paymentErrors.somethingWentWrongError',
  DO_NOT_HONOR: 'purchase.paymentErrors.somethingWentWrongError',
  ISSUER_TEMPORARILY_UNAVAILABLE:
    'purchase.paymentErrors.somethingWentWrongError',
}

export const CARD_PAYMENT_FIELD_NAMES = {
  NAME: 'name',
  NUMBER: 'cardNumber',
  EXPIRY: 'cardExpiry',
  CVC: 'cardCvc',
} as const

export const enum StripeSoftDeclineReason {
  AUTHENTICATION_FAILURE = 'payment_intent_authentication_failure',
  DO_NOT_HONOR = 'do_not_honor',
  INSUFFICIENT_FUNDS = 'insufficient_funds',
  INVALID_AMOUNT = 'invalid_amount',
  PROCESSING_ERROR = 'processing_error',
  REENTER_TRANSACTION = 'reenter_transaction',
  SERVICE_NOT_ALLOWED = 'service_not_allowed',
  TRANSACTION_NOT_ALLOWED = 'transaction_not_allowed',
  CARD_VELOCITY_EXCEEDED = 'card_velocity_exceeded',
}

export const STRIPE_SOFT_DECLINE_REASONS = [
  StripeSoftDeclineReason.AUTHENTICATION_FAILURE,
  StripeSoftDeclineReason.DO_NOT_HONOR,
  StripeSoftDeclineReason.INSUFFICIENT_FUNDS,
  StripeSoftDeclineReason.INVALID_AMOUNT,
  StripeSoftDeclineReason.PROCESSING_ERROR,
  StripeSoftDeclineReason.REENTER_TRANSACTION,
  StripeSoftDeclineReason.SERVICE_NOT_ALLOWED,
  StripeSoftDeclineReason.TRANSACTION_NOT_ALLOWED,
  StripeSoftDeclineReason.CARD_VELOCITY_EXCEEDED,
]

export const PRIMER_ERROR_CODE = {
  CARD_REQUIRED: 'cardRequired',
  CARD_IS_INCOMPLETE: 'cardIncomplete',
  CARD_IS_INVALID: 'cardInvalid',
  EXPIRY_REQUIRED: 'expiryRequired',
  CARD_IS_EXPIRED: 'cardExpired',
  CARD_EXPIRY_YEAR_INVALID: 'expiryYearInvalid',
  CARD_EXPIRY_MONTH_INVALID: 'expiryMonthInvalid',
  CVV_IS_INCOMPLETE: 'cvvIncomplete',
  CVV_IS_INVALID: 'cvvInvalid3',
}

export const CENTS_IN_DOLLAR = 100
export const DEFAULT_DYNAMIC_DISCOUNT_AMOUNT = 50
export const THREEDS_REDIRECT_SEARCH_PARAM = 'threeds'
export const PRIMER_SOFT_DECLINE = 'SOFT_DECLINE'
export const PRIMER_CARD_NUMBER_ID = 'checkout-card-number-input'
export const PRIMER_CARD_EXPIRY_ID = 'checkout-card-expiry-input'
export const PRIMER_CARD_CVC_ID = 'checkout-card-cvv-input'
export const PRIMER_CARD_HOLDER_ID = 'checkout-card-holder-input'
export const PRIMER_PAY_PAL_ID = 'paypal-button'
export const EMPTY_FIELD_ERROR = `can't be blank`
