export const CHECKOUT_BASE_ID = 'checkout'
export const UPSELL_BASE_ID = 'upsell'
export const PURCHASE_BASE_ID = 'purchase'
export const CANCEL_OFFER_BASE_ID = 'cancel_offer'
export const CANCEL_OFFER_QUESTION_BASE_ID = 'cancel_offer_question'

export const enum CustomPageId {
  ACTIVITY_DURING_WORK = 'activity_during_work',
  ACTIVITY_LEVEL = 'activity_level',
  AGE = 'age',
  AGE_RANGE = 'age_range',
  WORKOUT = 'workout',
  CURRENT_WEIGHT = 'current_weight',
  GENDER = 'gender',
  IDEAL_WEIGHT_TIME = 'ideal_weight_time',
  GOAL_WEIGHT = 'goal_weight',
  HEIGHT = 'height',
  PAST_TRIES = 'past_tries',
  PRIMARY_GOAL = 'primary_goal',
  PERIOD = 'period',
  EVENT_DATE = 'event_date',
  CHOOSE_EVENT = 'choose_event',
  MOTIVATION = 'motivation',
  FAVOURITE_FOODS = 'favourite_foods',
  RESTRICTIONS = 'restrictions',
  FASTING = 'fasting',
  TARGET_AREAS = 'target_areas_1',
  KETO_DIET = 'heard_about_keto',
}

export const enum PageId {
  GENDER_1 = 'gender_1',
  GENDER_2 = 'gender_2',
  GENDER_3 = 'gender_3',
  GENDER_4 = 'gender_4',
  GENDER_5 = 'gender_5',
  GENDER_6 = 'gender_6',
  PRIMARY_GOAL_1 = 'primary_goal_1',
  PRIMARY_GOAL_2 = 'primary_goal_2',
  PRIMARY_GOAL_3 = 'primary_goal_3',
  SECONDARY_GOAL_1 = 'secondary_goal_1',
  BODY_TYPE_1 = 'body_type_1',
  BODY_TYPE_2 = 'body_type_2',
  BODY_TYPE_3 = 'body_type_3',
  KIT_BODY_TYPE_4 = 'kit_body_type_1',
  DREAM_BODY_1 = 'dream_body_1',
  KIT_DREAM_BODY_1 = 'kit_dream_body_1',
  BREAKFAST_TIME_1 = 'breakfast_time_1',
  LUNCH_TIME_1 = 'lunch_time_1',
  DINNER_TIME_1 = 'dinner_time_1',
  MENU_1 = 'menu_1',
  KIT_HIT_YOUR_WEIGHT_LOSS_1 = 'kit_hit_your_weight_loss_1',
  FAVOURITE_FOODS_1 = 'favourite_foods_1',
  RESTRICTIONS_1 = 'restrictions_1',
  FEEL_HUNGRY_1 = 'feel_hungry_1',
  STOP_HUNGER_1 = 'stop_hunger_1',
  EATING_PLACE_1 = 'eating_place_1',
  FASTING_AT_WEEKEND_1 = 'fasting_at_weekend_1',
  KIT_LIFE_STYLE_1 = 'kit_life_style_1',
  KIT_IDEAL_WEIGHT_TIME_1 = 'kit_ideal_weight_time_1',
  WORKOUT_1 = 'workout_1',
  WORKOUT_2 = 'workout_2',
  WORK_SCHEDULE_1 = 'work_schedule_1',
  ACTIVITY_DURING_WORK_1 = 'activity_during_work_1',
  ACTIVITY_DURING_WORK_2 = 'activity_during_work_2',
  KIT_SLEEPING_TIME_1 = 'kit_sleeping_time_1',
  KIT_FEELINGS_TO_ACHIEVE_1 = 'kit_feelings_to_achieve_1',
  SLEEPING_TIME_1 = 'sleeping_time_1',
  SLEEPING_TIME_2 = 'sleeping_time_2',
  SLEEPING_TIME_FEEDBACK_1 = 'sleeping_time_feedback_1',
  KIT_DAILY_WATER_1 = 'kit_daily_water_1',
  DAILY_WATER_1 = 'daily_water_1',
  DAILY_WATER_2 = 'daily_water_2',
  DAILY_WATER_3 = 'daily_water_3',
  KIT_MOTIVATION_1 = 'kit_motivation_1',
  MOTIVATION_1 = 'motivation_1',
  COOKING = 'cooking',
  BAD_HABITS_FEEDBACK_1 = 'bad_habits_feedback_1',
  CHOOSE_EVENT_1 = 'choose_event_1',
  AGE_1 = 'age_1',
  AGE_2 = 'age_2',
  AGE_3 = 'age_3',
  KIT_AGE_1 = 'kit_age_1',
  AGE_RANGE_1 = 'age_range_1',
  AGE_RANGE_2 = 'age_range_2',
  AGE_RANGE_3 = 'age_range_3',
  AGE_RANGE_4 = 'age_range_4',
  HEIGHT_1 = 'height_1',
  HEIGHT_2 = 'height_2',
  HEIGHT_3 = 'height_3',
  CURRENT_WEIGHT_1 = 'current_weight_1',
  CALORIES_IMPACT_1 = 'calories_impact_1',
  CALORIES_EQUALITY_1 = 'calories_equality_1',
  ENERGY_VALUE_1 = 'energy_value_1',
  FEEL_GUILTY_1 = 'feel_guilty_1',
  STAY_ON_TRACK_1 = 'stay_on_track_1',
  CURRENT_WEIGHT_2 = 'current_weight_2',
  CURRENT_WEIGHT_3 = 'current_weight_3',
  KIT_CURRENT_WEIGHT_1 = 'kit_current_weight_1',
  GOAL_WEIGHT_1 = 'goal_weight_1',
  GOAL_WEIGHT_2 = 'goal_weight_2',
  GOAL_WEIGHT_3 = 'goal_weight_3',
  KIT_GOAL_WEIGHT_1 = 'kit_goal_weight_1',
  IDEAL_WEIGHT_TIME_1 = 'ideal_weight_time_1',
  IDEAL_WEIGHT_TIME_2 = 'ideal_weight_time_2',
  IDEAL_WEIGHT_TIME_3 = 'ideal_weight_time_3',
  IDEAL_WEIGHT_FEEDBACK_1 = 'ideal_weight_feedback_1',
  USERS_CHOICE_1 = 'users_choice_1',
  USERS_CHOICE_2 = 'users_choice_2',
  USERS_CHOICE_3 = 'users_choice_3',
  USERS_CHOICE_4 = 'users_choice_4',
  USERS_CHOICE_5 = 'users_choice_5',
  TARGET_AREAS_1 = 'target_areas_1',
  KIT_TARGET_AREAS_1 = 'kit_target_areas_1',
  PERFECT_SOLUTION_1 = 'perfect_solution_1',
  PERFECT_SOLUTION_2 = 'perfect_solution_2',
  ACHIEVE_RESULT_1 = 'achieve_result_1',
  ACHIEVE_RESULT_2 = 'achieve_result_2',
  ACHIEVE_RESULT_3 = 'achieve_result_3',
  STICKING_PLAN_1 = 'sticking_plan_1',
  STICKING_PLAN_3 = 'sticking_plan_3',
  ANALYZING_ANSWERS_1 = 'analyzing_answers_1',
  ANALYZING_ANSWERS_2 = 'analyzing_answers_2',
  PROFILE_SUMMARY_1 = 'profile_summary_1',
  PROFILE_SUMMARY_2 = 'profile_summary_2',
  YOU_ARE_IN_GOOD_HANDS_1 = 'you_are_in_good_hands_1',
  YOU_ARE_IN_GOOD_HANDS_2 = 'you_are_in_good_hands_2',
  YOYO_EFFECT_1 = 'yoyo_effect_1',
  YOYO_EFFECT_2 = 'yoyo_effect_2',
  EVENT_DATE_1 = 'event_date_1',
  EVENT_FEEDBACK_1 = 'event_feedback_1',
  KIT_WEIGHT_LOSS_DATE_1 = 'kit_weight_loss_date_1',
  PRESALE_1 = 'presale_1',
  PRESALE_2 = 'presale_2',
  KIT_PRESALE_1 = 'kit_presale_1',
  KIT_PRESALE_2 = 'kit_presale_2',
  HAVE_YOU_HEARD_1 = 'have_you_heard_1',
  BENEFITS_1 = 'benefits_1',
  FEELINGS_TO_ACHIEVE_1 = 'feelings_to_achieve_1',
  CHALLENGES_YOU_FACED_1 = 'challenges_you_faced_1',
  WHAT_ELSE_1 = 'what_else_1',
  YOU_ARE_NOT_ALONE_1 = 'you_are_not_alone_1',
  MEALS_PER_DAY_1 = 'meals_per_day_1',
  TIME_YOU_FEEL_HUNGRY_1 = 'time_you_feel_hungry_1',
  MEALS_PREPARATION_1 = 'meals_preparation_1',
  BETWEEN_MEALS_1 = 'between_meals_1',
  BAD_HABITS_1 = 'bad_habits_1',
  PAST_TRIES_1 = 'past_tries_1',
  DIET_TYPE_1 = 'diet_type_1',
  ACTIVITY_LEVEL_1 = 'activity_level_1',
  ACTIVITY_LEVEL_2 = 'activity_level_2',
  WALKING_1 = 'walking_1',
  BREATH_AFTER_CLIMBING_1 = 'breath_after_climbing_1',
  CONTRIBUTING_EVENTS_1 = 'contributing_events_1',
  CONTRIBUTING_EVENTS_2 = 'contributing_events_2',
  WEIGHT_GAIN_TRIGGERS_1 = 'weight_gain_triggers_1',
  WEIGHT_GAIN_RESULT_1 = 'weight_gain_result_1',
  GAIN_WEIGHT_MENOPAUSE_1 = 'gain_weight_period_1',
  GAIN_WEIGHT_PERIOD_1 = 'gain_weight_menopause_1',
  QUESTIONNAIRE_1 = 'questionnaire_1',
  QUESTIONNAIRE_2 = 'questionnaire_2',
  QUESTIONNAIRE_3 = 'questionnaire_3',
  QUESTIONNAIRE_4 = 'questionnaire_4',
  QUESTIONNAIRE_5 = 'questionnaire_5',
  QUESTIONNAIRE_6 = 'questionnaire_6',
  KIT_QUESTIONNAIRE_1 = 'kit_questionnaire_1',
  KIT_QUESTIONNAIRE_2 = 'kit_questionnaire_2',
  KIT_QUESTIONNAIRE_3 = 'kit_questionnaire_3',
  KIT_QUESTIONNAIRE_4 = 'kit_questionnaire_4',
  KIT_QUESTIONNAIRE_5 = 'kit_questionnaire_5',
  KIT_QUESTIONNAIRE_6 = 'kit_questionnaire_6',
  KIT_QUESTIONNAIRE_7 = 'kit_questionnaire_7',
  KIT_QUESTIONNAIRE_8 = 'kit_questionnaire_8',
  KIT_QUESTIONNAIRE_9 = 'kit_questionnaire_9',
  KIT_QUESTIONNAIRE_10 = 'kit_questionnaire_10',
  KIT_QUESTIONNAIRE_11 = 'kit_questionnaire_11',
  KIT_QUESTIONNAIRE_12 = 'kit_questionnaire_12',
  KIT_QUESTIONNAIRE_13 = 'kit_questionnaire_13',
  KIT_QUESTIONNAIRE_14 = 'kit_questionnaire_14',
  KIT_QUESTIONNAIRE_15 = 'kit_questionnaire_15',
  KIT_QUESTIONNAIRE_16 = 'kit_questionnaire_16',
  KIT_QUESTIONNAIRE_17 = 'kit_questionnaire_17',
  KIT_QUESTIONNAIRE_18 = 'kit_questionnaire_18',
  KIT_QUESTIONNAIRE_19 = 'kit_questionnaire_19',
  KIT_QUESTIONNAIRE_20 = 'kit_questionnaire_20',
  KIT_QUESTIONNAIRE_21 = 'kit_questionnaire_21',
  KIT_QUESTIONNAIRE_22 = 'kit_questionnaire_22',
  KIT_QUESTIONNAIRE_23 = 'kit_questionnaire_23',
  RADIO_QUESTION_1 = 'radio_question_1',
  CHECKBOX_QUESTION_1 = 'checkbox_question_1',
  KIT_DONT_EXERCISE_1 = 'kit_dont_exercise_1',
  KIT_UNIQUELY_TAILORED_PLAN_1 = 'kit_uniquely_tailored_plan_1',
  KIT_EMOTIONAL_EATING_1 = 'kit_emotional_eating_1',
  KIT_EASY_TO_FOLLOW_PLAN_1 = 'kit_easy_to_follow_plan_1',
  KIT_PERSONALIZE_YOUR_PLAN_1 = 'kit_personalize_your_plan_1',
  KIT_MEALS_1 = 'kit_meals_1',
  KIT_MEALS_2 = 'kit_meals_2',
  HOW_DETERMINED_ARE_YOU_1 = 'how_determined_are_you_1',
  MANAGEMENT = 'management',
  DATE_OF_BIRTH = 'date_of_birth',
  HOW_MANY_SQUATS_1 = 'how_many_squats_1',
  HELP_CALM_CRAVINGS = 'help_calm_cravings',
  NUTRIMATE_HELP = 'nutrimate_help',
  PERIOD_1 = 'period_1',
  PERIOD_AFFECT_APPETITE_1 = 'period_affect_appetite_1',
  PLAN_PACE_1 = 'plan_pace_1',
  LOWER_RISK_1 = 'lower_risk_1',
  KIT_CHALLENGES_YOU_FACED_1 = 'kit_challenges_you_faced_1',
  MEN_CHOICE_1 = 'men_choice_1',
  BELLY_TYPE_1 = 'belly_type_1',
  WE_ALL_GAIN_WEIGHT_1 = 'we_all_gain_weight_1',
  FEEDBACKS_1 = 'feedbacks_1',
  REASON_TO_GIVE_UP_1 = 'reason_to_give_up_1',
  PREPARE_PLAN_1 = 'prepare_plan_1',
  PREPARE_PLAN_2 = 'prepare_plan_2',
  CREATING_PLAN_1 = 'creating_plan_1',
  CREATING_PLAN_2 = 'creating_plan_2',
  CREATING_PLAN_3 = 'creating_plan_3',
  ENTER_EMAIL_1 = 'enter_email_1',
  ENTER_EMAIL_2 = 'enter_email_2',
  EMAIL_CONSENT_1 = 'email_consent_1',
  KIT_EMAIL_CONSENT_1 = 'kit_email_consent_1',
  KIT_EMAIL_CONSENT_2 = 'kit_email_consent_2',
  RESULT_1 = 'result_1',
  PURCHASE_1 = 'purchase_1',
  PURCHASE_3 = 'purchase_3',
  PURCHASE_4 = 'purchase_4',
  KIT_PURCHASE_1 = 'kit_purchase_1',
  KIT_PURCHASE_2 = 'kit_purchase_2',
  KIT_PURCHASE_3 = 'kit_purchase_3',
  KIT_PURCHASE_EMAIL_1 = 'kit_purchase_email_1',
  KIT_PURCHASE_EMAIL_2 = 'kit_purchase_email_2',
  CHECKOUT_1 = `${CHECKOUT_BASE_ID}_1`,
  CHECKOUT_2 = `${CHECKOUT_BASE_ID}_2`,
  KIT_CHECKOUT_1 = `kit_${CHECKOUT_BASE_ID}_1`,
  KIT_CHECKOUT_3 = `kit_${CHECKOUT_BASE_ID}_3`,
  KIT_CHECKOUT_4 = `kit_${CHECKOUT_BASE_ID}_4`,
  KIT_CANCEL_OFFER_QUESTION_1 = `kit_${CANCEL_OFFER_QUESTION_BASE_ID}_1`,
  KIT_CANCEL_OFFER_1 = `kit_${CANCEL_OFFER_BASE_ID}_1`,
  UPSELL_PAYWALL_1 = `${UPSELL_BASE_ID}_paywall_1`,
  UPSELL_PAYWALL_2 = `${UPSELL_BASE_ID}_paywall_2`,
  UPSELL_PAYWALL_3 = `${UPSELL_BASE_ID}_paywall_3`,
  UPSELL_PAYWALL_4 = `${UPSELL_BASE_ID}_paywall_4`,
  UPSELL_PAYWALL_5 = `${UPSELL_BASE_ID}_paywall_5`,
  FINISHING_TOUCHES = 'finishing_touches',
  LOGIN = 'login',
  DOWNLOAD = 'download',
  ACCOMPLISH = 'accomplish',
  PROGRAM_SUITE_1 = 'program_suite_1',
  IMPROVEMENTS_1 = 'improvements_1',
  START_JOURNEY_1 = 'start_journey_1',
  START_JOURNEY_2 = 'start_journey_2',
  ABOUT_FEELING_GOOD = 'about_feeling_good_1',
  THANK_YOU_FOR_YOUR_TRUST = 'thank_you_for_your_trust_1',
  THANK_YOU_FOR_YOUR_TRUST_2 = 'thank_you_for_your_trust_2',
  KIT_FASTING_1 = 'kit_fasting_1',
  FASTING_1 = 'fasting_1',
  FASTING_FEEDBACK_1 = 'fasting_feedback_1',
  EVENT_1 = 'event_1',
  EVENT_FEEDBACK_2 = 'event_feedback_2',
  KIT_AGE_RANGE_1 = 'kit_age_range_1',
  KIT_AGE_RANGE_2 = 'kit_age_range_2',
  KIT_GENDER_1 = 'kit_gender_1',
  KIT_USERS_CHOICE_1 = 'kit_users_choice_1',
  KIT_PRIMARY_GOAL_1 = 'kit_primary_goal_1',
  KIT_PRIMARY_GOAL_2 = 'kit_primary_goal_2',
  KIT_SMART_AI_1 = 'kit_smart_ai_1',
  KIT_AI_TECH_1 = 'kit_ai_tech_1',
  KIT_PERFECT_SOLUTION_1 = 'kit_perfect_solution_1',
  KIT_OTHER_STRUGGLES_1 = 'kit_other_struggles_1',
  KIT_REACH_GOALS_1 = 'kit_reach_goals_1',
  KIT_HEIGHT_1 = 'kit_height_1',
  KIT_NOT_ALONE_1 = 'kit_not_alone_1',
  KIT_ACHIEVE_RESULT_1 = 'kit_achieve_result_1',
  KIT_SUPPORT_1 = 'kit_support_1',
  KIT_CREATING_PLAN_1 = 'kit_creating_plan_1',
  KIT_CREATING_PLAN_2 = 'kit_creating_plan_2',
  KIT_FASTING_FEEDBACK_1 = 'kit_fasting_feedback_1',
  KIT_BAD_HABITS_1 = 'kit_bad_habits_1',
  KIT_FEEDBACKS_1 = 'kit_feedbacks_1',
  KIT_FEEDBACKS_2 = 'kit_feedbacks_2',
  KIT_EVENT_DATE_1 = 'kit_event_date_1',
  KIT_EVENT_FEEDBACK_1 = 'kit_event_feedback_1',
  KIT_PROFILE_SUMMARY_1 = 'kit_profile_summary_1',
  KIT_ENTER_EMAIL_1 = 'kit_enter_email_1',
  // TODO: remove it after kit-header refactoring
  KIT_DYNAMIC_FEELINGS_TO_ACHIEVE_1 = 'kit_dynamic_options_left_to_right_feelingsToAchieve',
  KIT_DYNAMIC_FASTING_1 = 'kit_dynamic_options_left_to_right_fasting',
  KIT_WEIGHT_LOSS_DATE_2 = 'kit_weight_loss_date_2',
  PRIVACY_POLICY = 'privacy-policy',
  TERMS_OF_USE = 'terms-of-use',
  KIT_MOTIVATION_PREVIEW = 'kit_motivation_preview_1',
  KIT_COMMIT = 'kit_commit_1',
  KIT_REACH_IDEAL_WEIGHT_1 = 'kit_reach_ideal_weight_1',
  KIT_REACH_IDEAL_WEIGHT_2 = 'kit_reach_ideal_weight_2',
  KIT_WORKOUT_1 = 'kit_workout_1',
  KIT_MOTIVATION_2 = 'kit_motivation_2',
  KIT_EVENT_1 = 'kit_event_1',
  KIT_SUGAR_1 = 'kit_sugar_1',
  KIT_KETO_DIET_1 = 'kit_keto_diet_1',
  KIT_RESET_METABOLISM_1 = 'kit_reset_metabolism_1',
}

export const enum PagePath {
  LOCALHOST = 'localhost',
  CHECKOUT = 'checkout',
}

export const PUBLIC_PAGES = [
  PageId.ENTER_EMAIL_1,
  PageId.ENTER_EMAIL_2,
  PageId.KIT_ENTER_EMAIL_1,
  PageId.EMAIL_CONSENT_1,
  PageId.KIT_EMAIL_CONSENT_1,
  PageId.KIT_EMAIL_CONSENT_2,
  PageId.RESULT_1,
  PageId.CREATING_PLAN_1,
  PageId.CREATING_PLAN_3,
  PageId.KIT_CREATING_PLAN_1,
  PageId.KIT_CREATING_PLAN_2,
  PageId.PRESALE_1,
  PageId.PRESALE_2,
  PageId.KIT_PRESALE_1,
  PageId.KIT_PRESALE_2,
]

export const UPSELL_PAYWALL_PAGES = [
  PageId.UPSELL_PAYWALL_1,
  PageId.UPSELL_PAYWALL_2,
  PageId.UPSELL_PAYWALL_3,
  PageId.UPSELL_PAYWALL_4,
  PageId.UPSELL_PAYWALL_5,
]

export const POST_PAYMENT_PAGES = [
  ...UPSELL_PAYWALL_PAGES,
  PageId.FINISHING_TOUCHES,
  PageId.LOGIN,
  PageId.DOWNLOAD,
]

export const enum ScreenName {
  ONBOARDING = 'onboarding',
  CANCEL_OFFER = 'cancel_offer',
  UPSELL_SWITCHER = 'upsell_switcher',
  CHECKOUT = 'checkout',
  UPSELL_COACHING = 'upsell_coaching',
  UPSELL_COACHING_CANCEL = 'upsell_coaching_cancel',
  UPSELL_LIST = 'upsell_list',
  MEALPREPING_TRACKING = 'mealpreping_tracking',
  FOODSCANER = 'foodscaner',
}
