import { OutbrainEventName, TaboolaEventName } from '~/shared/constants'
import { googleAnalyticsLogger } from '~/shared/services'
import { ISubscription } from '~/shared/types'

type TProps = {
  selectedSubscription?: ISubscription | null
  email: string
  uuid: string
  isPersonalDataAllowed: boolean
}

export const logGeneralAddToCartEvents = ({
  selectedSubscription,
  email,
  uuid,
  isPersonalDataAllowed,
}: TProps) => {
  if (selectedSubscription) {
    googleAnalyticsLogger.logAddingToCart(selectedSubscription)
  }

  window.snaptr('track', 'ADD_CART', {
    user_email: isPersonalDataAllowed ? email : '',
  })
  window.fbq(
    'track',
    'AddToCart',
    {},
    { eventID: isPersonalDataAllowed ? uuid : '' },
  )
  window.ttq.track('AddToCart')
  window.obApi?.('track', OutbrainEventName.ADD_TO_CART)
  window._tfa?.push({
    notify: 'event',
    name: TaboolaEventName.ADD_TO_CART,
  })
}
