import styled from 'styled-components'

import { Color } from '~/shared/constants'

type TProps = {
  marginBottom: number
}

export const StyledLogo = {
  Wrapper: styled.div<TProps>`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: ${({ marginBottom }) => marginBottom}px;
  `,

  LogoContainer: styled.div`
    align-items: center;
    column-gap: 12px;
    display: flex;
  `,

  Logo: styled.div`
    aspect-ratio: 1 / 1;
    width: 24px;
  `,

  LogoText: styled.div`
    color: ${Color.BLACK_100};
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
  `,
}
