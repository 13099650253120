import { useEffect, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { CustomPageId, MEN_COHORTS, WOMEN_COHORTS } from '~/shared/constants'
import {
  selectCurrentUserGender,
  selectCurrentVariantCohortToUse,
  setAnswersAction,
} from '~/shared/store/common'
import { sendUserAnswersAction } from '~/shared/store/user'

export const useGenderCohortInitialization = () => {
  const dispatch = useDispatch()

  const userGender = useSelector(selectCurrentUserGender)
  const cohortToUse = useSelector(selectCurrentVariantCohortToUse)

  const isGenderCohort = useMemo(
    () => [...MEN_COHORTS, ...WOMEN_COHORTS].includes(cohortToUse),
    [cohortToUse],
  )

  useEffect(() => {
    if (isGenderCohort) {
      dispatch(
        setAnswersAction({
          answers: userGender,
          pageId: CustomPageId.GENDER,
        }),
      )
      dispatch(sendUserAnswersAction())
    }
  }, [dispatch, isGenderCohort, userGender])
}
